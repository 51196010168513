const skills = [
  {
    skill_name:"HTML",
    skill_value:"80",
    skill_color:"html",
    title_color:"#34495e",
  },

  {
    skill_name:"CSS",
    skill_value:"85",
    skill_color:"css",
    title_color:"#3498db",
  }
,
  {
    skill_name:"JAVASCRIPT",
    skill_value:"80",
    skill_color:"javascript",
    title_color:"#f1c40f",
  }
,
  {
    skill_name:"REACT",
    skill_value:"85",
    skill_color:"react",
    title_color:"#0652DD",
  }
,
  {
    skill_name:"MATERIAL-UI",
    skill_value:"90",
    skill_color:"materialui",
    title_color:"#007FFF",
  },
  {
    skill_name:"TAILWIND-CSS",
    skill_value:"75",
    skill_color:"tailwindcss",
    title_color:"#0984e3",
  }
  ,
  {
    skill_name:"REACT-FORMIK",
    skill_value:"60",
    skill_color:"reactformik",
    title_color:"#e84393",
  },
  {
    skill_name:"REACT-QUERY",
    skill_value:"70",
    skill_color:"reactquery",
    title_color:"##eb4d4b",
  }
]

export default skills