import React from 'react'
import {Box,Typography,LinearProgress} from '@mui/material'
const ProgressBar = ({skill_name,skill_value,skill_color,title_color}) => {
  return (
    <Box>
      <Typography mb={1} color={title_color} letterSpacing={2} fontWeight={"bold"}>
            {skill_name}
          </Typography>
          <LinearProgress
            color={skill_color}
            variant="determinate"
            value={skill_value}
            sx={{borderRadius:"12px" ,padding:"4px"}}
          ></LinearProgress>
    </Box>
  )
}

export default ProgressBar