import React, { useEffect } from "react";
import "./Skills.css";
import { Box, Stack, Typography, Grid } from "@mui/material";
import skills from "../../data/skills";
import skills2 from "../../data/skills2";
import ProgressBar from "./ProgressBar";
import Aos from 'aos'
import 'aos/dist/aos.css'
const Skills = () => {
  useEffect(()=>{
    Aos.init();
  })
  return (
    <Box sx={{ padding: { xs: "80px 40px" } }} id="skills">
      <Stack sx={{ marginBottom: { xs: "40px", sm: "50px" } }}>
        <Typography
          variant="h5"
          sx={{
            margin: "0 auto",
            textAlign: "center",
            width: "180px",
            borderRadius: "12px",
            borderBottom: "4px solid #484C9C",
            padding: "10px",
            fontWeight: "bold",
            letterSpacing: ".1em",
          }}
          color="primary"
        >
          MY SKILLS
        </Typography>
      </Stack>

      <Grid container sx={{gap:{xs:"40px",sm:"100px"}}}  justifyContent={"center"}>
        <Grid item sx={{xs:"5"}}  sm="4">
          <Stack gap={2} data-aos="zoom-in" >
            {skills.map((data, index) => (
              <ProgressBar
                skill_name={data.skill_name}
                skill_value={data.skill_value}
                skill_color={data.skill_color}
                title_color={data.title_color}
                key={index}
              />
            ))}
          </Stack>
        </Grid>

        <Grid item sx={{xs:"5"}} sm="4">
        <Stack gap={2} data-aos="zoom-in">
            {skills2.map((data, index) => (
              <ProgressBar
                skill_name={data.skill_name}
                skill_value={data.skill_value}
                skill_color={data.skill_color}
                title_color={data.title_color}
                key={index}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Skills;
