import React from 'react'
import  './Main.css'
import { Box } from '@mui/material'
import Hero from '../Hero/Hero'

import Skills from '../Skills/Skills'
import Educations from '../Educations/Educations'

import Contact from '../Contact/Contact'
import Projects from '../Projects/Projects'
import CounterUp from '../CounterUp/CounterUp'
const Main = () => {
  return (
    <Box >
      <Hero/>
      <Skills/>
      <Educations/>
      <Projects/>
      <CounterUp/>
      <Contact/>
    </Box>
  )
}

export default Main