import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import bgimg from '../../assets/bg.png'
const CounterUp = () => {
  const [counterStart, setCounterStart] = useState(false);
  return (
    <>
      <ScrollTrigger
        onEnter={() => setCounterStart(true)}
        onExit={() => setCounterStart(false)}
      >
        <section className="text-gray-600 body-font" >
          <div className="container px-5 py-24 mx-auto" >
            <div className="flex flex-wrap -m-4 text-center">
              {/* Statistic block for Projects */}
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                  {counterStart && (
                    <CountUp start={0} end={25} duration={2} delay={0} />
                  )}
                </h2>
                <p className="text-slate-800 leading-relaxed">Projects Completed</p>
              </div>

              {/* Statistic block for Technologies */}
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                  {counterStart && (
                    <CountUp start={0} end={8} duration={2} delay={0} />
                  )}
                </h2>
                <p className="text-slate-800 leading-relaxed">Technologies Used</p>
              </div>

              {/* Statistic block for Clients */}
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                  {counterStart && (
                    <CountUp start={0} end={15} duration={2} delay={0} />
                  )}
                </h2>
                <p className="text-slate-800 leading-relaxed">Happy Clients</p>
              </div>

              {/* Statistic block for GitHub Stars */}
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                  {counterStart && (
                    <CountUp start={0} end={500} duration={2} delay={0} />
                  )}
                </h2>
                <p className="text-slate-800 leading-relaxed">GitHub Stars</p>
              </div>
            </div>
          </div>
        </section>
      </ScrollTrigger>
    </>
  );
};

export default CounterUp;
