import React from 'react'
import './Loader.css'
import { Box } from '@mui/material'
const Loader = () => {
  return (
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
      <div class="loader"></div>
    </Box>
  )
}

export default Loader
