import React, { useEffect } from "react";
import "./Educations.css";
import { Box, Stack, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Aos from "aos";
import "aos/dist/aos.css";

const Educations = () => {
  // Initialize Aos on component mount
  useEffect(() => {
    Aos.init();
  });

  return (
    <Box sx={{ padding: { xs: "80px 40px", sm:"80px 40px"},  }} id="educations">
      <Stack sx={{ marginBottom: { xs: "40px", md: "50px" } }}>
        {/* Section Title */}
        <Typography
          variant="h5"
          sx={{
            margin: "0 auto",
            textAlign: "center",
            width: "200px",
            borderRadius: "12px",
            borderBottom: "4px solid #484C9C",
            padding: "10px",
            fontWeight: "bold",
            letterSpacing: ".1em",
          }}
          color="primary"
        >
          EDUCATIONS
        </Typography>
      </Stack>

      <Stack>
        {/* Timeline */}
        <Timeline position="alternate-reverse">

          {/* First Timeline Item */}
          <TimelineItem data-aos="zoom-in">
            <TimelineSeparator>
              {/* Timeline Dot */}
              <TimelineDot color="secondary" />
              
              {/* Timeline Connector */}
              <TimelineConnector
                sx={{
                  height: "200px",
                  backgroundColor: "orange",
                  borderRadius: "12px",
                  width: "3px",
                }}
              />
            </TimelineSeparator>
            {/* Timeline Content */}
            <TimelineContent>
              <Typography
                variant="h6"
                sx={{
                  color: "#F72798",
                  fontWeight: "bold",
                  letterSpacing: ".1em",
                }}
              >
                Amity Univeristy Patna
              </Typography>
              <Typography variant="h5" color="initial">
                {" "}
                <span className="clg_course">B.C.A</span> (Bachelor of Computer
                Applications)
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "1.1rem", color: "#484C9C" },
                }}
              >
                Passing Year : <span className="clg_course">2022-2025</span>{" "}
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {/* Second Timeline Item */}
          <TimelineItem data-aos="zoom-in">
            <TimelineSeparator>
              <TimelineDot color="primary" />
              <TimelineConnector
                sx={{
                  height: "200px",
                  backgroundColor: "orange",
                  borderRadius: "12px",
                  width: "3px",
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="h6"
                sx={{
                  color: "#F72798",
                  fontWeight: "bold",
                  letterSpacing: ".1em",
                }}
              >
                Gorakh Singh Collage Maharajganj
              </Typography>
              <Typography color="initial">
                Bihar School Examination Board{" "}
                <span className="sch_course">(B.S.E.B)</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "11px", sm: "1.1rem", color: "#484C9C" },
                }}
              >
                Passed Out : <span className="sch_course">2019-2021</span>{" "}
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {/* Third Timeline Item */}
          <TimelineItem data-aos="zoom-in">
            <TimelineSeparator>
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="h6"
                color
                sx={{
                  color: "#F72798",
                  fontWeight: "bold",
                }}
              >
                Saraswati Vidya Mandir
              </Typography>
              <Typography>( Keshav Nagar Maharajganj )</Typography>
              <Typography color="initial">
                Central Board of Secondary Education{" "}
                <span className="sch_course"> (C.B.S.E)</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "1.1rem", color: "#484C9C" },
                }}
              >
                Passed Out : <span className="sch_course">2013-2019</span>{" "}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Stack>
    </Box>
  );
};



export default Educations;
