import { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  FormControl,
  TextField,
  Typography,
  Button,
  FormLabel,
  Paper,
  Alert,
} from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);


 
  const handleSubmit = (event) => {
    event.preventDefault();
    setName("");
    // setEmail("");
    setMessage("");
    setSubject("");

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  return (
    <Box
      sx={{
        padding: { xs: "80px 40px", sm: " 40px" },
        background: 'linear-gradient(115deg, #ffffff, #ffe3fb)'
      }}
      id="contact"
    >
      <Stack sx={{ marginBottom: { xs: "40px", md: "50px" } }}>
        {/* Section Title */}
        <Typography
          variant="h5"
          sx={{
            margin: "0 auto",
            textAlign: "center",
            width: "220px",
            borderRadius: "12px",
            borderBottom: "4px solid #484C9C",
            padding: "10px",
            fontWeight: "bold",
            letterSpacing: ".1em",
          }}
          color="primary"
        >
          CONTACT ME
        </Typography>
      </Stack>

      <Stack>
        <FormControl
          component="form"
          onSubmit={handleSubmit}
          data-aos="zoom-in"
        >
          <FormLabel
            sx={{
              textAlign: "center",
              textTransform: "capitalize",
              marginBottom: "1rem",
              fontWeight: "bold",
              color: "#130F40",
            }}
          >
            Let's connect! Drop me a line and let the conversation begin.
          </FormLabel>

          <Paper
            sx={{
              width: { xs: "100%", sm: "70%" },
              padding: { xs: "1.2rem", sm: "2rem" },
              margin: "0 auto",
            }}
          >
            <TextField
              fullWidth
              label="Name"
              type="text"
              InputProps={{ style: { color: "#130F40" } }}
              sx={{ marginBottom: "1rem" }}
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <TextField
              fullWidth
              label="Email"
              type="email"
              InputProps={{ style: { color: "#130F40" } }}
              sx={{ marginBottom: "1rem" }}
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <TextField
              fullWidth
              label="Subject"
              type="text"
              InputProps={{ style: { color: "#130F40" } }}
              sx={{ marginBottom: "1rem" }}
              required
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />

            <TextField
              fullWidth
              label="Message"
              type="text"
              multiline
              rows={4}
              InputProps={{ style: { color: "#130F40" } }}
              sx={{ marginBottom: "1rem" }}
              required
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />

            <Button
              type="submit"
              sx={{ backgroundColor: "#F72798", borderRadius: "50px" }}
              variant="contained"
              fullWidth
            >
              Submit
            </Button>
          </Paper>
        </FormControl>
      </Stack>

      {/* Alert message */}
      {showAlert && (
        <Alert severity="success" sx={{ marginTop: "1rem" }}>
          Form submitted successfully!
        </Alert>
      )}
    </Box>
  );
};

export default Contact;
