import { Box, Stack, Typography } from "@mui/material";
import ProjectCard from "./ProjectCard";
import project from "../../data/projects";
const Projects = () => {
  return (
    <Box sx={{ padding: { xs: "80px 40px", sm: "40px"},background: 'linear-gradient(115deg, #ffffff, #ffe3fb)'}}  id="projects">
      <Stack sx={{ marginBottom: { xs: "40px", md: "50px" } }}>
        {/* Section Title */}
        <Typography
          variant="h5"
          sx={{
            margin: "0 auto",
            textAlign: "center",
            width: "180px",
            borderRadius: "12px",
            borderBottom: "4px solid #484C9C",
            padding: "10px",
            fontWeight: "bold",
            letterSpacing: ".1em",
          }}
          color="primary"
        >
          PROJECTS
        </Typography>
      </Stack>
      <Stack
        justifyContent={"center"}
        gap={6}
        flexDirection={"row"}
        flexWrap={"wrap"}
      >
        {project.map((data, index) => (
          <ProjectCard
            key={index}
            project_img={data.project_img}
            project_title={data.project_title}
            description={data.description}
            project_link={data.project_link}
            github_url={data.github_url}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Projects;
