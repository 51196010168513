
import { createTheme } from "@mui/material";


const Themes = createTheme({
  // palette is use for the colors 

  palette: {
    primary: {
      main: '#130f40',// Blue
    },
    secondary: {
      main: '#686de0', // Yellow
    },
    ternary:{
      main:'#f9ca24'
    },
   
    background: {
      default: '#F5F5F5', // Light Grey
    },
    text: {
      primary: '#ffff', // Dark Text
      secondary: '#757575',
       // Grey Text
    },
    html:{
      main:"#34495e"
    },
    css:{
      main:"#3498db"
    },
    javascript:{
      main:"#f1c40f"
    },
    react:{
      main:"#0652DD"
    }
    ,
    materialui:{
      main:"#007FFF"
    },
    tailwindcss:{
      main:"#0984e3"
      
    },
    reactformik:{
      main:"#e84393"
    },
    reactquery:{
      main:"#4834d4"
    },
    nodejs:{
      main:"#2ed573"
    },
    expresjs:{
      main:"#5352ed"
    },
    mongodb:{
      main:"#137357"
    },
    github:{
      main:"#1e272e"
    },
    cpp:{
      main:"#ef5777"
    },
    java:{
      main:"#474787"
    },
    c:{
      main:"#218c74"
    }
  },
});

export default  Themes

