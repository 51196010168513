import { Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Aos from 'aos'
import 'aos/dist/aos.css';
const ProjectCard = ({
  project_img,
  project_title,
  description,
  project_link,
  github_url,
}) => {

  useEffect(()=>{
    Aos.init();
  })
  return (
  
    <Card sx={{ maxWidth: 345}} data-aos="zoom-in">
      <CardMedia
        sx={{ height: 220 }}
        image={project_img}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" color="primary" component="div">
          {project_title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
      <Link to={project_link} target="_blank">
        <Button size="small" sx={{borderRadius:"50px"}} variant="contained">live Demo</Button>
      </Link>
       
       <Link to={github_url} target="_blank">
        <Button size="small" sx={{borderRadius:"50px",backgroundColor:"#F72798"}}   variant="contained">Project Github</Button>
       </Link>
        
      </CardActions>
    </Card>



  );
};

export default ProjectCard;
