const skills = [
  {
    skill_name:"NODE JS",
    skill_value:"75",
    skill_color:"nodejs",
    title_color:"#2ed573",
  },

  {
    skill_name:"EXPRESS JS",
    skill_value:"80",
    skill_color:"expresjs",
    title_color:"#5352ed",
  }
,
  {
    skill_name:"MONGO DB",
    skill_value:"70",
    skill_color:"mongodb",
    title_color:"#137357",
  }
,
  {
    skill_name:"GIT AND GITHUB",
    skill_value:"85",
    skill_color:"github",
    title_color:"#1e272e",
  }
,
  {
    skill_name:"C++",
    skill_value:"87",
    skill_color:"cpp",
    title_color:"#ef5777",
  },
  {
    skill_name:"JAVA",
    skill_value:"82",
    skill_color:"java",
    title_color:"#474787"
  }
  ,
  {
    skill_name:"C",
    skill_value:"90",
    skill_color:"c",
    title_color:"#218c74",
  },
  {
    skill_name:"RechartS",
    skill_value:"80",
    skill_color:"c",
    title_color:"#22B5BF",
  }
]

export default skills