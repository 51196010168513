import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Footer, Main, Navbar } from "./components";
import Loader from "./components/loader/Loader";
import aos  from "aos";
import Aos from 'aos/dist/aos'
const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    Aos.init();
  })
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    },1100)
  })
  if(loading){
    return <div data-aos="zoom-in"><Loader/></div>
  }

  return (
    <Router>
      <Navbar />
      <Main />
      <Footer />
    </Router>
  );
};

export default App;
