import acrypto_project1 from "../assets/acrypto_project1.png";
import Etm_bike from "../assets/etm_bike_project2.png";
import vidio_hub from "../assets/vidio hub project3.png";
import Techphilia from '../assets/techphilia.png'
import CandyCrush from '../assets/candy_crush.png'
import infosphere from '../assets/infosphere.png'
const project = [
  {
    project_img: acrypto_project1,

    project_title: "Acrypto-App",
    description:
      "This project is a Crypto Market Overview web application built using React, Chakra UI, and Context API. The application fetches cryptocurrency data from a hypothetical API endpoint on component mount, utilizing the useEffect hook. ",
    project_link: "https://acrypto.netlify.app/",
    github_url: "https://github.com/Ashukr321/crypto_api",
  },
  {
    project_img: Etm_bike,
    project_title: "ETM Bike",
    description:
      "Create an Electric Two-Wheeler (ETM) Bike web app using React, Chakra UI, and Context API. Fetch bike data from a hypothetical API endpoint, manage global state with  Context API,  and build a  dynamic and visually appealing user interface.useEffect hooks. ",
    project_link: "https://etm-bike.vercel.app/",
    github_url: "https://github.com/Ashukr321/etm-bike",
  },
  {
    project_img: vidio_hub,
    project_title: "Video-Hub",
    description:
      "Developed a React app with Chakra UI, enabling seamless video lecture uploads. Utilized Context API for state management. Enhance student engagement by creating an interactive platform for accessing and learning from uploaded lectures.",
    project_link: "https://vdhub.netlify.app/",
    github_url: "https://github.com/Ashukr321/video-hub",
  },

  {
    project_img: Techphilia ,
    project_title: "Techphilia 7.0",
    description:
      "Experience the future at our tech-centric extravaganza! Dive into the world of innovation, coding challenges, and cutting-edge workshops. Join us for a collision of creativity and technology at our electrifying tech fest. Unleash your inner technophile!",
    project_link: "https://www.aiitpevent.in/",
    github_url: "https://github.com/Ashukr321/Techphilia",
  },
  {
    project_img: CandyCrush ,
    project_title: "Candy-Crush🍬",
    description:
      "Build a captivating Candy Crush web app using React and hooks. Dive into innovative coding challenges, employ cutting-edge workshops, and unleash your technophile spirit at our electrifying tech fest. Join the collision of creativity and technology!",
    project_link: "https://candy-crush-rosy-six.vercel.app/",
    github_url: "#home",
  },
  {
    project_img: infosphere,
    project_title: "🌐Infosphere",
    description:
      "Explore the latest in our cutting-edge news and finance platform. Immerse yourself in a seamless blend of global headlines and real-time financial insights. Navigate an enhanced user interface for a dynamic and informed experience. Join us on the forefront of news and finance",
    project_link: "https://infosphere.vercel.app/",
    github_url: "/",
  },
];

export default project;


